<template>
    <div>
        <h1 class="title">全网最全搜索引擎</h1>
    </div>

</template>

<script>

export default {
    name:"TopHeader",
}
</script>

<style scoped>
.title {
    text-align: center;
}

</style>
