<template>
<div>
    <van-grid column-num="2"  clickable :gutter="3">
        <van-grid-item v-for="video in videos" :key="video.key" @title="video.title" @click="handleVideo(video)">
        
                    <van-image  :src="video.icon" :error-icon="imageDefault" fit="fill"/><br/>
                    <div class="van-multi-ellipsis--l2">{{ video.title }}</div>

 
        </van-grid-item>
    </van-grid>
</div>

</template>

<script>
import DefaultImage from '@/assets/images/defaultimg.webp'
export default {
    name:"VideoList",
    data(){
        return {
            imageDefault:DefaultImage
        }
    },
    props:["videos","videoClick"],
    methods:{
        handleVideo(video) {
            if (this.videoClick) {
                this.videoClick(video);
                return;
            }

            this.$router.push({
                path:"/play",
                query:video
            });
        },
    },
}
</script>
<style>
 
</style>