<template>
  <div> <div>
        <van-nav-bar :title="title" left-text="返回" left-arrow 
            @click-left="onLeftClick"  
            style="background-color: yellow;" />
    </div>
    <div>

        <van-tabs v-model="activeName" @change="onTabChanged">
            <van-tab v-for="item in plats" :title="item.title" :name="item.id" :key="item.id">
                
                    <div v-show="item.loading" style="text-align: center;"><van-loading /></div>
               
                <VideoList :videos="item.video" :videoClick="onTapVideo" ></VideoList>
            </van-tab>
        </van-tabs>
    </div>
</div>  
</template>

<script>
import VideoList from '@/components/VideoList.vue';
import Utils from '@/utils/Utils';
import HttpHelper from '@/utils/HttpHelper';

export default {
    name:'SearchResult',
    data(){
        return {
            title:"",
            activeName:"",
            searchkey:"",
            plats:[],
            orgPlats:[],
        }
    },

    computed:{

    },

    props:["handleBack","videoClick"],

    activated(){
        var searchkey = Utils.getSessionKey("searchkey");
        this.orgPlats = JSON.parse(Utils.getSessionKey("platids"));

        if (searchkey != this.searchkey && searchkey != "" && this.orgPlats && this.orgPlats.length > 0) {
            this.searchkey = searchkey;

            while(this.plats.shift());

            for (var i = 0; i < this.orgPlats.length; i++) {
                this.plats.push({
                    id:this.orgPlats[i].id,
                    title:this.orgPlats[i].title,
                    loaded:false,
                    loading:false,
                    video:[]
                })
            }

            this.activeName = this.plats[0].id
            this.doPlatSearch(this.activeName);
        }

        this.title = searchkey;
    },

    components:{
        VideoList
    },

    methods:{
        onLeftClick() {
            if (this.handleBack) {
                this.handleBack();
                return;
            }
        },

        onTapVideo(vd){
            if (this.videoClick) {
                this.videoClick(vd)
            }
        },

        getPlatIndex(id) {
            for (var i = 0; i < this.plats.length; i++) {
                if (this.plats[i].id == id) {
                    return i;
                } 
            }

            return -1;
        },

        doPlatSearch(platId) {
            var index = this.getPlatIndex(platId);
            if  (index < 0) return;
                                                                                       
            if (this.plats[index].loaded) return;

            this.plats[index].loading = true;

            HttpHelper.doSearch("", platId, this.searchkey,"",(code, datas)=>{
                if (code != 200) {
                    this.plats[index].loading = false;
                  
                    return
                }

                if (datas && datas.length > 0) {
                    datas.forEach(element => {
                        this.plats[index].video.push(element);
                        
                    });
                }

                this.plats[index].loading = false;
                this.plats[index].loaded = true;
               

               
            });
        },

        onTabChanged(name) {
            this.doPlatSearch(name)
        }
    }
}
</script>