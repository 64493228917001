<template>
    <div>
        <van-swipe :autoplay="3000">
            <van-swipe-item v-for="item in banners" :key="item.href">
                <van-image  :src="item.icon"  @click="onClicked(item.href)" fit="fill"/>
            </van-swipe-item>
        </van-swipe>

    </div>
</template>

<script>
export default {
    name:"TopBanner",
    props:["banners"],

    methods:{
        onClicked(key) {
            window.open(key, '_blank');
        }
    }
}
</script>