import {nanoid} from 'nanoid'

function getStorage(key) {
    var ret = localStorage.getItem(key);
    if (!ret) return "";

    return ret;
}

function setStorage(key,value) {
    localStorage.setItem(key, value);
}

export default class Utils {
    Utils() {

    }

    static playFreeSpan = 0;

    static getSessionKey(key) {
        var ret = sessionStorage.getItem(key);
        if (!ret) return "";

        return ret;
    }


    static getLocalData(key) {
        return getStorage(key);
    }   

    static setLocalData(key, v) {
        setStorage(key,v);
    }

    static setSessionKey(key, data) {
        sessionStorage.setItem(key, data);
    }

    static removeSessionKey(key) {
        sessionStorage.removeItem(key)
    }

    static  getDevNo() {
        return getStorage("userdevno")
    }
    
    static setDevNo(devNo) {
        setStorage("userdevno", devNo);
    }
    
    static generageDevNo() {
        return nanoid();
    }
    
    static getNavigator() {
        return getStorage("hphnavigator")
    }
    
    static setNavigator(guanWang) {
        setStorage("hphnavigator", guanWang)
    }

    static setFreeSpan(newSpan) {
        Utils.playFreeSpan = newSpan
    }

    static getFreeSpan() {
        return Utils.playFreeSpan
    }

    static openUrl(href) {
        window.open(href, '_blank');
    }
}

