<template>
<div>
    <van-nav-bar :title="title" left-text="返回" left-arrow 
        @click-left="onLeftClick"  
        @click-right="onRightClick"
        style="background-color: yellow;"
        >
        <template #right>
            <van-icon name="search" size="18" />
        </template>
    </van-nav-bar>
    
     <keep-alive include="CatsList">
        <component v-bind:is="currentTabComponent" @appCatatVideo="onTurnCatVideo"></component>
    </keep-alive>
     
</div>
</template>

<script>
import CatsList from './CatsList.vue';
import CatVideo from './CatVideo.vue';
import Utils from '@/utils/Utils';
export default {
    name:"AppCats",
    components:{
        CatsList,
        CatVideo,
    },

    data(){
        return {
            allComponets:["CatsList","CatVideo"],
            showIndex:0,
            title:"",
        }
    },
    computed:{
        currentTabComponent(){
            return this.allComponets[this.showIndex];
        },
    },
    methods:{
        onLeftClick(){
            if (this.showIndex > 0) {
                this.showIndex = 0;
                return;
            }
            
            this.$router.back();
        },
        onRightClick(){
            this.$router.push("/search");
        },

        onTurnCatVideo(key) {
           
            Utils.setSessionKey("catKey",key);
            this.showIndex = 1;
        },

        onTurnCatList(){
            this.showIndex = 0;
        }
    },

    mounted(){
        this.title = this.$route.query.title;
    }
}
</script>