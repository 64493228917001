<template>
    <div>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadMore">
        <VideoList :videos="videos"></VideoList>
    </van-list>

    </div>
</template>

<script>
import HttpHelper from '@/utils/HttpHelper';
import Utils from '@/utils/Utils';
import VideoList from './VideoList.vue';
export default {
    name:"CatVideo",
    data(){
        return {
            isLoading:true,
            loading:false,
            finished:false,
            videos:[],
            catKey:"",
            lastId:"",
        }
    },

    components:{
        VideoList
    },

    computed:{
        app(){
            return this.$route.query;
        }
    },

    mounted() {
        this.catKey = Utils.getSessionKey("catKey")

        HttpHelper.queryCatVideos("", this.catKey, this.lastId,(code, datas)=>{
            if (code != 200) {
                console.log("query cat video fail ", code);
                return;
            }

            this.videos = datas;

            if (datas && datas.length > 0) {
                this.lastId = datas[datas.length-1].key;

                if (datas.length < HttpHelper.videoPageSize) {
                        this.finished = true; 
                }
            }
        });
    },
    methods:{
        onLoadMore(){
            this.loading = true;
            HttpHelper.queryCatVideos("", this.catKey, this.lastId,(code, datas)=>{
                if (code != 200) {
                    this.loading = false;
                    return;
                }

                if (datas && datas.length > 0) {
                    this.lastId = datas[datas.length- 1].id;

                    datas.forEach(element => {
                        this.videos.push(element);
                    });

                    if (datas.length < HttpHelper.videoPageSize) {
                        this.finished = true; 
                    }
                } else {
                    this.finished = true;   
                }

                this.loading = false;
            });

                   
        }
    }
}
</script>