<template>
  
    <div>
  

      <keep-alive>
        <component v-bind:is="currentTabComponent"></component>
      </keep-alive>
      <p>&nbsp;</p>
      <van-tabbar  v-model="activeIndex" active-color="#ee0a24" inactive-color="#000" @change="onChange">
        <van-tabbar-item>
          <span>最新</span>
          <template #icon="props">
            <img :src="props.active ? newIcon.active : newIcon.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item >
          <span>推荐</span>
          <template #icon="props">
            <img :src="props.active ? recommIcon.active : recommIcon.inactive" />
          </template>
      </van-tabbar-item>
      <van-tabbar-item >
          <span>更多</span>
          <template #icon="props">
            <img :src="props.active ? moreIcon.active : moreIcon.inactive" />
          </template>
      </van-tabbar-item>
  </van-tabbar>
    </div>
  </template>
    
<script>
import NewSelected from '@/assets/images/icon_tab_new_selected.webp'
import NewNormal from '@/assets/images/icon_tab_new_normal.webp'
import RecommSelected from '@/assets/images/icon_tab_tui_jian_selected.webp'
import RecommNormal from '@/assets/images/icon_tab_tui_jian_normal.webp'
import MoreSelected from '@/assets/images/icon_tab_more_selected.webp'
import MoreNormal from '@/assets/images/icon_tab_more_normal.webp'

import AppCats from '@/components/AppCats.vue'
import AppIndex from '@/components/AppIndex.vue'
import AppRecomm from '@/components/AppRecomm.vue'

import GlobalConfig from '@/utils/GlobalConfig'

export default {
    name:'PlayHome',
    data(){
        return {
            activeIndex:0,
            title:this.$route.query.title,
            tabComponents:["AppIndex", "AppRecomm", "AppCats"],
            newIcon: {
                active: NewSelected,
                inactive: NewNormal,
            },
            recommIcon: {
                active: RecommSelected,
                inactive: RecommNormal,
            },
            moreIcon: {
                active: MoreSelected,
                inactive: MoreNormal,
            }
        }
    },

    components:{
        AppCats,
        AppIndex,
        AppRecomm
    },

    computed:{
        currentTabComponent(){
            return this.tabComponents[this.activeIndex];
        }
    },
    methods :{
      onChange(){

      },
      onLeftClick(){
            this.$router.back();
      },
      onRightClick(){
          this.$router.push("/search");
      },
    },
    mounted(){
      if (!GlobalConfig.getInstance().reported) {
            this.$router.replace("/");
            return;
      }
    }
}
</script>