<template>
    <div>
        <template v-if="loading">
            <div style="text-align: center;">
                <van-loading />
            </div>
        </template>
        
    <van-row type="flex" justify="center">
        
    <van-col span="7" v-for="cat in cats"  :key="cat.key" style="margin-top: 20px;">
        <van-tag mark color="#ddd" text-color="#333" size="large" @click="handleCatVideo(cat)">
            {{ shortTitle(cat.title) }}
        </van-tag>
    </van-col>
    
    </van-row>
    <br/>
</div>
</template>

<script>
import HttpHelper from '@/utils/HttpHelper';
export default {
    name:"CatsList",
    data(){
        return {
            cats:[],
            lastId:"",
            loading:true
        }
    },
    computed:{
        app(){
            return this.$route.query;
        },
        
    },

    mounted(){
        HttpHelper.queryAppCats("", this.app.key,(code,datas)=>{
            if (code != 200) {
                console.log("query cats list fail ", code)
                this.loading = false;
                return;
            }

           
            this.cats = datas;
            this.loading = false;
        })
    },

    methods:{
        shortTitle(plain) {
            return plain.slice(0,6)
        },
        
        handleCatVideo(cat) {
           

            this.$emit("appCatatVideo", cat.key)
        }
    }
}
</script>