<template>
<div>
    <van-nav-bar :title="title" left-text="返回" left-arrow 
        @click-left="onLeftClick"  
        @click-right="onRightClick"
        style="background-color: yellow;"
        >
        <template #right>
            <van-icon name="search" size="18" />
        </template>
    </van-nav-bar>
    <van-pull-refresh v-model="isLoading" @refresh="refreshVideos">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadMore">
        <VideoList :videos="videos"></VideoList></van-list>
    </van-pull-refresh> 
    <br/>  
</div>

</template>

<script>
import VideoList from './VideoList.vue';
import HttpHelper from '@/utils/HttpHelper';

export default {
    name:'AppIndex',
    data(){
        return {
            isLoading:true,//上拉刷新
            loading:false, //下拉加载更多
            finished:false,//下拉无更多数据
            title:"",
            lastId:"",
            videos:[],
        }
    },
  
    computed:{
        app(){
            return this.$route.query;
        },
    },

    methods:{
        onLeftClick(){
                this.$router.back();
            },
            onRightClick(){
                this.$router.push("/search");
            },
        refreshVideos(){
            this.isLoading = true;

            HttpHelper.queryAppNewVideos("", this.app.key, this.lastId,(code,datas)=>{
                if (code != 200) {
                    this.isLoading = false;
                    return;
                }

                this.videos = datas;
                this.isLoading = false;
                
                if (datas && datas.length > 0) {
                    this.lastId = datas[datas.length- 1].id;
                    if (datas.length < HttpHelper.videoPageSize) {
                        this.finished = true; 
                    }
                }
            })
        },

        onLoadMore(){
            this.loading = true;

            HttpHelper.queryAppNewVideos("", this.app.key, this.lastId,(code,datas)=>{
                if (code != 200) {
                    this.loading = false;
                    return;
                }

                if (datas && datas.length > 0) {
                    this.lastId = datas[datas.length- 1].id;

                    datas.forEach(element => {
                        this.videos.push(element);
                    });

                    if (datas.length < HttpHelper.videoPageSize) {
                        this.finished = true; 
                    }
                } else {
                    this.finished = true;   
                }

                this.loading = false;
            })           
        }
    },

    components:{
        VideoList
    },

    mounted(){
        this.title = this.app.title;
        this.refreshVideos();
    },
}
</script>