<template>
<div>
    <div >
        <table style="width:100%;padding: 0 0 0 0; background-color: #fff;">
            <tr>
               
                <td><van-icon name="arrow-left" @click="onBackClick" size="1.5rem"/></td>
                <td>

                    <van-search
                    show-action
                   placeholder="请输入搜索关键词"
                   shape="round"
                    v-model="searchKey" 
                   >
                   <template #action>
                       <van-button plain type="info" round size="small" @click="onClickSearch">搜索</van-button>
                   </template>
               </van-search>
                </td>
            </tr>
        </table>
    </div>
    <div>
        <van-cell-group inset  >
            <van-cell title="历史搜索" icon="underway-o">
                <template #right-icon>
                    <van-icon name="delete-o" class="search-icon" @click="clearHistoryKeys"/>
                </template>
            </van-cell>
            <br/>

            <van-row type="flex" justify="center">
        
        <van-col span="7" v-for="index in oldKeys"  style="margin-top: 20px;" :key="index">
            <van-tag round color="#ddd" text-color="#111" size="large" @click="onTagClick(index)">
                {{ index }}
            </van-tag>
        </van-col>
        
        </van-row>
        <br/>
        </van-cell-group>
        
    </div>
 
   

</div>
</template>

<script>
import Utils from '@/utils/Utils';
import { Dialog } from 'vant';

export default {
    name:'SearchIndex',
    data(){
        return {
            searchKey:"",
            oldKeys:[],
        }
    },

    props:["handleSearch","handleBack"],

    mounted(){
        var keys = Utils.getLocalData("historySearch");

        if (keys) {
            this.oldKeys= JSON.parse(keys);
        }
    },

    methods:{
        onBackClick(){
            if (this.handleBack) {
                this.handleBack();
                return;
            }

            this.$router.back();
        },

        addHistoryKey(key) {
            this.oldKeys.unshift(key);
            
            Utils.setLocalData("historySearch", JSON.stringify(this.oldKeys))
        },

        onClickSearch() {
            if (!this.searchKey.trim()) {
                console.log("search key is empty")
                return;
            }

            var idx = -1;
            for (var i = 0; i < this.oldKeys.length; i++){
                if (this.oldKeys[i] == this.searchKey.trim()) {
                    idx = i;
                    break;
                }
            }
           
            if (idx === -1) {
                this.addHistoryKey(this.searchKey.trim())
            }
                
            if (this.handleSearch) {
                var key = this.searchKey.trim();
                this.searchKey = "";

                this.handleSearch(key)
            } else {
                console.log("handleSearch is empty")
            }

            this.searchKey = "";
        },

        onTagClick(key) {
            this.searchKey = key;

            this.onClickSearch()
        },

        clearHistoryKeys(){
            if (!this.oldKeys || this.oldKeys.length < 1) return;

            Dialog.confirm({
                message: '确定删除所有历史搜索记录?',
                })
                .then(() => {
                    Utils.setLocalData("historySearch", "[]")
                    while (this.oldKeys.shift()) ;

                    this.oldKeys = [];
                })
                .catch(() => {
                    // on cancel
            });
 
        }
    }
}
</script>