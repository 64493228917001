<template>
    <div>
<van-pull-refresh v-model="isLoading" @refresh="onRefresh" clickable style="width:100%;">
    <van-grid :column-num="4" style="width:100%;" >
        <van-grid-item v-for="(app, index) in apps" :key="app.key+app.id + app.kind + '-'+ index "   @click="onClick(app)" >
            
                 <van-image  :src="app.icon"  fit="fill" :error-icon="appImageDefault"
                    style="width: 4rem;height: 4rem;">
                
                   
                </van-image> 
                    
                 
                    <br/>
           
           
               {{ app.title }}
                    
        </van-grid-item>
    </van-grid>
</van-pull-refresh>
</div>
</template>

<script>
import Utils from '@/utils/Utils';
import DefaultAppImg from '@/assets/images/default_app.png'
export default {
    name :"AppList",
    data() {
        return {
            isLoading:true,
            appImageDefault:DefaultAppImg,
        }
    },

    props:[
        "apps",
        "fetchList"
    ],

    methods:{
        onRefresh() {
            if (!this.fetchList) {
                this.isLoading = false;
                return;
            }

            this.fetchList(()=>{
                this.isLoading = false;
            })
        },

        onClick(app) {
       

            if (app.kind == 1) {
                Utils.openUrl(app.key);
                return;
            }

            var newRoute = {
                path:"/app",
                query:app,
            };

            this.$router.push(newRoute)
        }
    }
}
</script>

<style lang="less">
    
</style>