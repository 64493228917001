<template>
    <div @click="onSearch">
      <van-cell-group>
        <van-search
            v-model="searchKey"
            show-action
            shape="round"
            placeholder="请输入搜索关键词"
        
            >
            <template #action>
                <van-button color="#7232dd" plain size="small" round >搜索</van-button>
            </template>
            </van-search></van-cell-group>
    </div>
</template>

<script>
export default {
    name:"TopSearch",
    data() {
        return {
            searchKey:"",
        }
    },
    methods:{
        onSearch() {
            this.$router.push("/search")
        }
    }
}
</script>